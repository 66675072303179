import React, { Suspense, useLayoutEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
// Preloader
const Preloader = React.lazy(() => import("./components/layouts/Preloader"));

// Home
const Hometwo = React.lazy(() => import("./components/pages/Hometwo"));
const Homethree = React.lazy(() => import("./components/pages/Homethree"));
const Homefour = React.lazy(() => import("./components/pages/Homefour"));
// Blog
const Bloggrid = React.lazy(() => import("./components/pages/Bloggrid"));
const Bloglist = React.lazy(() => import("./components/pages/Bloglist"));
const Blogdetails = React.lazy(() => import("./components/pages/Blogdetails"));
// Pages
const About = React.lazy(() => import("./components/pages/About"));
const Contact = React.lazy(() => import("./components/pages/Contact"));
const Team = React.lazy(() => import("./components/pages/Team"));
const Faqs = React.lazy(() => import("./components/pages/Faqs"));
// Services
const Services = React.lazy(() => import("./components/pages/Services"));
const Servicestwo = React.lazy(() => import("./components/pages/Servicestwo"));
const Servicedetails = React.lazy(() =>
  import("./components/pages/Servicedetails")
);
// Cases
const Casestudies = React.lazy(() => import("./components/pages/Casestudies"));
const Casestudy = React.lazy(() => import("./components/pages/Casestudy"));
// Appointment
const Appointment = React.lazy(() => import("./components/pages/Appointment"));
// Shop
const Shop = React.lazy(() => import("./components/pages/Shop"));
const Productsingle = React.lazy(() =>
  import("./components/pages/Productsingle")
);
const Wishlist = React.lazy(() => import("./components/pages/Wishlist"));
const Cart = React.lazy(() => import("./components/pages/Cart"));
const Checkout = React.lazy(() => import("./components/pages/Checkout"));
const Error = React.lazy(() => import("./components/pages/Error"));

// Scroll to Top
const ScrollToTop = withRouter(({ children, location: { pathname } }) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children || null;
});

function App() {
  return (
    //<Router basename={"/themes/themeforest/react/Confiable Consultancy/"}>
    <Router basename={"/"}>
      <Suspense fallback={<div></div>}>
        <ScrollToTop>
          <Preloader />
          <Switch>
            {/* Home */}
            <Route exact path="/" component={Homethree} />
            <Route exact path="/home-v2" component={Hometwo} />
            <Route exact path="/home" component={Homethree} />
            <Route exact path="/home-v4" component={Homefour} />
            {/* Blog */}
            <Route
              exact
              path="/blog/cat/:catId"
              component={(props) => (
                <Bloggrid {...props} key={window.location.pathname} />
              )}
            />
            <Route
              exact
              path="/blog/tag/:tagId"
              component={(props) => (
                <Bloggrid {...props} key={window.location.pathname} />
              )}
            />
            <Route
              exact
              path="/blog/author/:authorId"
              component={(props) => (
                <Bloggrid {...props} key={window.location.pathname} />
              )}
            />
            <Route
              exact
              path="/blog/search/:query"
              component={(props) => (
                <Bloggrid {...props} key={window.location.pathname} />
              )}
            />
            <Route exact path="/blog-grid" component={Bloggrid} />
            <Route exact path="/blog-list" component={Bloglist} />
            <Route
              exact
              path="/blog-details/:id"
              component={(props) => (
                <Blogdetails {...props} key={window.location.pathname} />
              )}
            />
            {/* Pages */}
            <Route exact path="/about" component={About} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/team" component={Team} />
            <Route exact path="/faqs" component={Faqs} />
            {/* Services */}
            <Route exact path="/services" component={Services} />
            <Route exact path="/services-v2" component={Servicestwo} />
            <Route
              exact
              path="/service-details/:id"
              component={(props) => (
                <Servicedetails {...props} key={window.location.pathname} />
              )}
            />
            {/* Cases */}
            <Route
              exact
              path="/case/cat/:catId"
              component={(props) => (
                <Casestudies {...props} key={window.location.pathname} />
              )}
            />
            <Route exact path="/case-studies" component={Casestudies} />
            <Route
              exact
              path="/case-study/:id"
              component={(props) => (
                <Casestudy {...props} key={window.location.pathname} />
              )}
            />
            {/* Appointment */}
            <Route exact path="/appointment" component={Appointment} />
            {/* Shop */}
            <Route
              exact
              path="/shop/cat/:catId"
              component={(props) => (
                <Shop {...props} key={window.location.pathname} />
              )}
            />
            <Route
              exact
              path="/shop/tag/:tagId"
              component={(props) => (
                <Shop {...props} key={window.location.pathname} />
              )}
            />
            <Route
              exact
              path="/shop/search/:query"
              component={(props) => (
                <Shop {...props} key={window.location.pathname} />
              )}
            />
            <Route
              exact
              path="/shop/:minPrice/:maxPrice"
              component={(props) => (
                <Shop {...props} key={window.location.pathname} />
              )}
            />
            <Route exact path="/shop" component={Shop} />
            <Route
              exact
              path="/product-single/:id"
              component={(props) => (
                <Productsingle {...props} key={window.location.pathname} />
              )}
            />
            <Route exact path="/wishlist" component={Wishlist} />
            <Route exact path="/cart" component={Cart} />
            <Route exact path="/checkout" component={Checkout} />
            <Route exact path="/error-page" component={Error} />
            <Route exact component={Error} />
          </Switch>
        </ScrollToTop>
      </Suspense>
    </Router>
  );
}

export default App;
